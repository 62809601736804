// =============================================================================
//
// Prebid constants
//
// hb_ prefix are key value that should be auto-populated by prebid.js
//
// =============================================================================
import { GPT_COMPONENT_ID, SIZE_MAPPING_NAMES } from '@/lib/ads/constants/adSlots'
import { type MediaTypes } from '@/lib/ads/types/adConfig'

type BidCpmAdjustment = Record<string, { bidCpmAdjustment: (bidCpm: number) => number }>

type AdserverTargeting = {
  key: string
  val: (bid: Record<string, string | number>) => string | number
}

type BidderSettings = {
  standard: {
    adserverTargeting: AdserverTargeting[]
  }
} & Record<string, object>

export type Pbjs = { bidderSettings: BidderSettings; version: string } & BidCpmAdjustment

declare global {
  // Prebid attaches itself to the window object as pbjs
  interface Window {
    pbjs: Pbjs
  }
}

/**
 * each bidder has their own unique params which can vary in type
 * so best to keep the type set as unknown
 * */
export type BidConfig = {
  bidder: string
  params: Record<string, unknown>
  responsiveRules: Record<string, never>
  labelAny?: string[] // for SRP (comes from GOT)
  labels?: string[] // for homepage (comes from legacy config)
}

export type PrebidConfig = {
  mediaTypes: MediaTypes
  bids?: BidConfig[]
}

export const PREBID_KEYS = {
  PREBID_AUCTION_PRICE: 'hb_pb',
  /** Name of the bidding partner */
  PREBID_BIDDING_PARTNER: 'hb_bidder',
  /** Size of the ad width and height (ex: 300x250) */
  PREBID_AD_SIZE: 'hb_size',
  /** Ad Id */
  PREBID_AD_ID: 'hb_adid',
  PREBID_DEAL_ID: 'hb_deal',
  PREBID_TIME_TO_RESPOND: 'hb_bid_time',
  PREBID_AD_SOURCE: 'hb_source',
  /** Format of the ad (ex: banner) */
  PREBID_AD_FORMAT: 'hb_format',
}

/**
 * Values for initializing prebid custom config.
 * Based on the production values from banners.js
 * in box-canada-webapp-static
 */
export const PREBID_CONFIG = {
  BRACKET_PENNIES: 10.0, // 10 dollars
  BRACKET_NICKELS: 25.0, // 25 dollars
  PENNIES_PER_$: 100,
  NICKELS_PER_$: 20,
  MAX_BUCKET: '101300',
}

/**
 * Prebid bidder configs copied from GOT requests
 * Settings are ad-unit, device and locale specific
 */

type PrebidBidderSettings = {
  desktop: {
    en: BidConfig[]
    fr: BidConfig[]
  } | null
  mweb: {
    en: BidConfig[]
    fr: BidConfig[]
  } | null
}

const TOP_LEADERBOARD_BIDS: PrebidBidderSettings = {
  desktop: {
    en: [
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 582474,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.TOP_LEADERBOARD]],
      },
      {
        bidder: 'smartadserver',
        params: {
          formatId: 112843,
          pageId: 1599355,
          siteId: 508684,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.TOP_LEADERBOARD]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_TOP_ENGLISH_728x90',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.TOP_LEADERBOARD]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '538714691',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.TOP_LEADERBOARD]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 10477244,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.TOP_LEADERBOARD]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190663',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.TOP_LEADERBOARD]],
      },
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_SearchResults_Desktop_InFeed_Top',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.TOP_LEADERBOARD]],
      },
    ],
    fr: [
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825116,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.TOP_LEADERBOARD]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190679',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.TOP_LEADERBOARD]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 29258446,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.TOP_LEADERBOARD]],
      },
    ],
  },
  mweb: null,
}

const INLINE_1_BIDS: PrebidBidderSettings = {
  desktop: {
    en: [
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_SearchResults_desktop_inline1',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '538714692',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'smartadserver',
        params: {
          formatId: 112838,
          pageId: 1599355,
          siteId: 508684,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 10477245,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190670',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '540415749',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 1092776,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 14286334,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_INLINE1_ENGLISH_728x90',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 582476,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '315133',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_INLINE1_ENGLISH_BT',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
    ],
    fr: [
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825098,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 21137829,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 21137830,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190680',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '951386',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825096,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
    ],
  },
  mweb: {
    en: [
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_SearchResults_desktop_inline1',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '538714692',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'smartadserver',
        params: {
          formatId: 112838,
          pageId: 1599355,
          siteId: 508684,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 10477245,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190670',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '540415749',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 1092776,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 14286334,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_INLINE1_ENGLISH_728x90',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 582476,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '315133',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_INLINE1_ENGLISH_BT',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
    ],
    fr: [
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825098,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 21137829,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 21137830,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190680',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '951386',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825096,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
    ],
  },
}

const INLINE_2_BIDS: PrebidBidderSettings = {
  desktop: {
    en: [
      {
        bidder: 'smartadserver',
        params: {
          formatId: 112839,
          pageId: 1599355,
          siteId: 508684,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_SearchResults_desktop_inline1',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '315134',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '540415767',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 582478,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 14286336,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190671',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_INLINE2_ENGLISH_BT',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_INLINE2_ENGLISH_728x90',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 10477246,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '538714693',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 1092780,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
    ],
    fr: [
      {
        bidder: 'appnexus',
        params: {
          placementId: 21137831,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825102,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825100,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190681',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 21137832,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '951387',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
    ],
  },
  mweb: {
    en: [
      {
        bidder: 'smartadserver',
        params: {
          formatId: 112839,
          pageId: 1599355,
          siteId: 508684,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_SearchResults_desktop_inline1',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '315134',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '540415767',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 582478,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 14286336,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190671',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_INLINE2_ENGLISH_BT',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_INLINE2_ENGLISH_728x90',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 10477246,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '538714693',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 1092780,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
    ],
    fr: [
      {
        bidder: 'appnexus',
        params: {
          placementId: 21137831,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825102,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825100,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190681',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 21137832,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '951387',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
    ],
  },
}

const INLINE_3_BIDS: PrebidBidderSettings = {
  desktop: {
    en: [
      {
        bidder: 'appnexus',
        params: {
          placementId: 18317550,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'smartadserver',
        params: {
          formatId: 112841,
          pageId: 1599355,
          siteId: 508684,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_SearchResults_desktop_inline3',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '540955328',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 18317560,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 1549838,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '540955320',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: '651715',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '452978',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '452985',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_INLINE3_ENGLISH',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 1549852,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
    ],
    fr: [
      {
        bidder: 'appnexus',
        params: {
          placementId: 29258441,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '951393',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825106,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825104,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 29258439,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '455086',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
    ],
  },
  mweb: {
    en: [
      {
        bidder: 'appnexus',
        params: {
          placementId: 18317550,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'smartadserver',
        params: {
          formatId: 112841,
          pageId: 1599355,
          siteId: 508684,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_SearchResults_desktop_inline3',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '540955328',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 18317560,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 1549838,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '540955320',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: '651715',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '452978',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '452985',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_INLINE3_ENGLISH',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 1549852,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
    ],
    fr: [
      {
        bidder: 'appnexus',
        params: {
          placementId: 29258441,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '951393',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825106,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825104,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 29258439,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '455086',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
    ],
  },
}

const INLINE_4_BIDS: PrebidBidderSettings = {
  desktop: {
    en: [
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '540955321',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '540955329',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 29258442,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_SearchResults_desktop_inline3',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 1549854,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '452986',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_INLINE4_ENGLISH',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_INLINE4_ENGLISH_BT',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'smartadserver',
        params: {
          formatId: 112842,
          pageId: 1599355,
          siteId: 508684,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '452979',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 1549840,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 18317561,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
    ],
    fr: [
      {
        bidder: 'appnexus',
        params: {
          placementId: 29258443,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 29258442,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825108,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '951394',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825110,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '455087',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
    ],
  },
  mweb: {
    en: [
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '540955321',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '540955329',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 29258442,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_SearchResults_desktop_inline3',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 1549854,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '452986',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_INLINE4_ENGLISH',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_INLINE4_ENGLISH_BT',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'smartadserver',
        params: {
          formatId: 112842,
          pageId: 1599355,
          siteId: 508684,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '452979',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 1549840,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 18317561,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
    ],
    fr: [
      {
        bidder: 'appnexus',
        params: {
          placementId: 29258443,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 29258442,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825108,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '951394',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825110,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '455087',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.INLINE]],
      },
    ],
  },
}

const MINI_LEADERBOARD_BIDS: PrebidBidderSettings = {
  desktop: {
    en: [
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_Inline_Native_TextwithImage_desktop',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MINI_LEADERBOARD]],
      },
    ],
    fr: [
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_Inline_Native_TextwithImage_desktop_French',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MINI_LEADERBOARD]],
      },
    ],
  },
  mweb: {
    en: [
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_Inline_Native_TextwithImage_desktop',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MINI_LEADERBOARD]],
      },
    ],
    fr: [
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_Inline_Native_TextwithImage_desktop_French',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MINI_LEADERBOARD]],
      },
    ],
  },
}

const BASE_LEADERBOARD_BIDS: PrebidBidderSettings = {
  desktop: {
    en: [
      {
        bidder: 'appnexus',
        params: {
          placementId: 14286339,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_SearchResults_Desktop_InFeed_Baseboard',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '540415773',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: '346263',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190672',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '315135',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '538714694',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_BASEBOARD_ENGLISH_728x90',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 10477247,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'smartadserver',
        params: {
          formatId: 112837,
          pageId: 1599355,
          siteId: 508684,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 1092782,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 582480,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
    ],
    fr: [
      {
        bidder: 'ix',
        params: {
          siteId: '190682',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 21137827,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825092,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825094,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 21137828,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '951388',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
    ],
  },
  mweb: {
    en: [
      {
        bidder: 'appnexus',
        params: {
          placementId: 14286339,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_SearchResults_Desktop_InFeed_Baseboard',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '540415773',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: '346263',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190672',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '315135',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '538714694',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_BASEBOARD_ENGLISH_728x90',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 10477247,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'smartadserver',
        params: {
          formatId: 112837,
          pageId: 1599355,
          siteId: 508684,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 1092782,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 582480,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
    ],
    fr: [
      {
        bidder: 'ix',
        params: {
          siteId: '190682',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 21137827,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825092,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825094,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 21137828,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '951388',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.BASE_LEADERBOARD]],
      },
    ],
  },
}

const SKYSCRAPER_BIDS: PrebidBidderSettings = {
  desktop: {
    en: [
      {
        bidder: 'appnexus',
        params: {
          placementId: 14286345,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]],
      },
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_SearchResults_Desktop_InFeed_LeftRail',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '315136',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190673',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_LEFT_RAIL_ENGLISH_160x600',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_LEFT_RAIL_ENGLISH_BT',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 1092784,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 582482,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 10477248,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]],
      },
      {
        bidder: 'smartadserver',
        params: {
          formatId: 112840,
          pageId: 1599355,
          siteId: 508684,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '538714696',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '540415785',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]],
      },
    ],
    fr: [
      {
        bidder: 'appnexus',
        params: {
          placementId: 29258445,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190683',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 21137834,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825112,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 475888,
          zoneId: 2825114,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '951389',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.SKYSCRAPER]],
      },
    ],
  },
  mweb: null,
}

const MWEB_LEADERBOARD_BIDS: PrebidBidderSettings = {
  desktop: null,
  mweb: {
    en: [
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 582474,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MWEB_LEADERBOARD]],
      },
      {
        bidder: 'smartadserver',
        params: {
          formatId: 112843,
          pageId: 1599355,
          siteId: 508684,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MWEB_LEADERBOARD]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_TOP_ENGLISH_728x90',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MWEB_LEADERBOARD]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '538714691',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MWEB_LEADERBOARD]],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '540415741',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MWEB_LEADERBOARD]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 10477244,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MWEB_LEADERBOARD]],
      },
      {
        bidder: 'rubicon',
        params: {
          accountId: 12230,
          siteId: 45324,
          zoneId: 1092774,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MWEB_LEADERBOARD]],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '156591',
          adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_TOP_ENGLISH_BT',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MWEB_LEADERBOARD]],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190663',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MWEB_LEADERBOARD]],
      },
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_SearchResults_Desktop_InFeed_Top',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MWEB_LEADERBOARD]],
      },
    ],
    fr: [
      {
        bidder: 'ix',
        params: {
          siteId: '190679',
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MWEB_LEADERBOARD]],
      },
      {
        bidder: 'appnexus',
        params: {
          placementId: 29258446,
          publisherId: 821042,
        },
        responsiveRules: {},
        labelAny: [SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.MWEB_LEADERBOARD]],
      },
    ],
  },
}

export const PREBID_SLOT_CONFIGS = {
  [GPT_COMPONENT_ID.TOP_LEADERBOARD]: TOP_LEADERBOARD_BIDS,
  [GPT_COMPONENT_ID.INLINE_1]: INLINE_1_BIDS,
  [GPT_COMPONENT_ID.INLINE_2]: INLINE_2_BIDS,
  [GPT_COMPONENT_ID.INLINE_3]: INLINE_3_BIDS,
  [GPT_COMPONENT_ID.INLINE_4]: INLINE_4_BIDS,
  [GPT_COMPONENT_ID.MINI_LEADERBOARD]: MINI_LEADERBOARD_BIDS,
  [GPT_COMPONENT_ID.BASE_LEADERBOARD]: BASE_LEADERBOARD_BIDS,
  [GPT_COMPONENT_ID.SKYSCRAPER]: SKYSCRAPER_BIDS,
  [GPT_COMPONENT_ID.MWEB_LEADERBOARD]: MWEB_LEADERBOARD_BIDS,
}
